import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { protectedRoute } from "@/utils/routeUtils";
import RouteTypeValidation from "../components/RouteTypeValidation";

const moduleTitle = "module_title.customer_invoice_information_sheet";
const allowedRoles = ["isApprover"];
const allowedTypes = ["pending", "history"];

export const RedirectToList = () => {
  const userRoles = useSelector((state) => state.auth?.userRoles);
  const navigate = useNavigate();

  useEffect(() => {
    if (userRoles?.length > 0) navigate("/ciis/pending", { replace: true });
  }, [userRoles, navigate]);

  return null;
};

const ciisRoutes = {
  path: "ciis",
  title: moduleTitle,
  sidebarProps: {
    key: "1-3",
    iconSrc: "ciis",
    label: "module_title.ciis",
    link: "/ciis",
    groupingType: "TOOLS",
    order: 3,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: <RedirectToList />,
    },
    {
      path: ":type",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          element: (
            <RouteTypeValidation
              allowedTypes={allowedTypes}
              fallback={protectedRoute(
                [() => import("@/pages/ciis/CIISList")],
                allowedRoles
              )}
            />
          ),
        },
        {
          path: ":id",
          title: moduleTitle,
          headerProps: {
            backUrl: "/ciis/:type",
          },
          element: (
            <RouteTypeValidation
              allowedTypes={allowedTypes}
              fallback={protectedRoute(
                [() => import("@/pages/ciis/CIISDetails")],
                allowedRoles
              )}
            />
          ),
        },
      ],
    },
  ],
};

export default ciisRoutes;
