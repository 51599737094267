import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import BoHeader from "./_components/BoHeader";
import BoSidebar from "./_components/BoSidebar";
import BoNotification from "./_components/BoNotification";
import { Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";
import { appActions } from "@/store/slices/app";
import { useDispatch, useSelector } from "react-redux";
import { allPrivateRoutes } from "@/router";
import { useCustomMediaQuery } from "@/hooks";
import { useUserApi } from "@/api/user";
import { authActions } from "@/store/slices/auth";
import BackTopButton from "@/components/BackTopButton";

const flatMapRoute = ({ children, index, title, path, headerProps }) => {
  if (Array.isArray(children) && children?.length) {
    return children
      .map(({ path: childPath, index, ...rest }) => ({
        ...rest,
        index,
        path: `${path}/${childPath}`,
      }))
      .flatMap((child) =>
        child.index
          ? { index: child.index, title, path, headerProps }
          : flatMapRoute(child)
      );
  }
  return { index, title, path, headerProps };
};

const generateRegexWithId = (inputString) => {
  const idPattern = "([a-zA-Z0-9-]+)"; //Accept alphanumeric and hyphen
  const escapedString = inputString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regexString = escapedString.replace(/:id/, idPattern);
  return new RegExp(`^${regexString}$`);
};

const RawPrivateLayout = ({ className }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useCustomMediaQuery();
  const boContentRef = useRef(null);

  const [notifDrawerOpen, setNotifDrawerOpen] = useState(false);
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(false);

  const profileUuid = useSelector((state) => state.auth.uuid);

  const { getUserProfile } = useUserApi();

  useEffect(() => {
    getAdminProfile();
  }, []);

  useEffect(() => {
    const allPossibleRoutes = allPrivateRoutes.flatMap(flatMapRoute);
    const normalizedPathname = location.pathname.replace(/\/+$/, "");

    if (location.pathname.endsWith("/") && location.pathname !== "/") {
      navigate(normalizedPathname, { replace: true });
      return;
    }

    const matchedRoute = findMatchedRoute(
      allPossibleRoutes,
      normalizedPathname
    );

    if (matchedRoute?.index) {
      handleIndexRouteRedirection(allPossibleRoutes, matchedRoute);
    }

    dispatch(
      appActions.setCurrentRouteObj({
        title: matchedRoute?.title,
        path: matchedRoute?.path,
        headerProps: matchedRoute?.headerProps,
      })
    );
  }, [location.pathname, navigate]);

  const getAdminProfile = async () => {
    if (!profileUuid) return;

    try {
      const res = await getUserProfile(profileUuid);
      if (res.status !== "ok") return;

      const userMainRole = res.result?.userRole?.role?.code;
      const userSubRoles = res.result?.userRole
        ? Object.entries(res.result.userRole)
            .filter(
              ([key, value]) =>
                [
                  "isRequester",
                  "isReviewer",
                  "isApprover",
                  "isVisaApprover",
                ].includes(key) && value === true
            )
            .map(([key]) => key)
        : [];

      const userRoles = [userMainRole, ...userSubRoles];

      dispatch(authActions.setProfileObj(res.result));
      dispatch(authActions.setUserRoles(userRoles));
    } catch (error) {
      console.error(error);
    }
  };

  const findMatchedRoute = (allPossibleRoutes, normalizedPathname) => {
    return allPossibleRoutes.find((route) => {
      if (matchPath(`/${route.path}`, normalizedPathname)) {
        return route;
      }

      const splittedCurrPath = normalizedPathname.split("/");
      const targetPath = splittedCurrPath.slice(1).join("/");
      const regex = generateRegexWithId(route.path);

      if (regex.test(targetPath)) {
        return route;
      }

      return false;
    });
  };

  const handleIndexRouteRedirection = (allRoutes, matchedRoute) => {
    const currentIndex = allRoutes.findIndex(
      ({ index, path }) => index && path === matchedRoute.path
    );

    const nextRoute = allRoutes[currentIndex + 1];
    if (!nextRoute) return;

    const getLastSegment = (path) => path.substring(path.lastIndexOf("/") + 1);
    const isDynamicParam = (segment) => segment.startsWith(":");

    const currentPath = location.pathname;
    const matchedPathLastSegment = getLastSegment(matchedRoute.path);
    const nextPath = nextRoute.path;
    const nextPathLastSegment = getLastSegment(nextPath);

    if (!nextRoute.index) {
      if (!isDynamicParam(matchedPathLastSegment)) {
        if (isDynamicParam(nextPathLastSegment)) {
          // Case: [/path/:id] Path with dynamic segment transitions within same level
          // Eg: /expatriate-document/ -> /expatriate-document
          navigate(currentPath, { replace: true });
        } else {
          // Case: [/path/details] Static path transitions to a sub path
          // Eg: /dropdown-list/ or /dropdown-list -> /dropdown-list/human-resource-management
          navigate(`/${nextPath}`, { replace: true });
        }
      } else {
        if (isDynamicParam(nextPathLastSegment)) {
          // Case: [/path/:type/:id] Path with dynamic segment transitions within the same level
          // Eg: Initial load of /ciis, /ciis/pending/ -> /ciis/pending
          navigate(currentPath, { replace: true });
        } else {
          // Case: [/path/:id/details] Dynamic path with a sub path transitions to a new sub path
          // Eg: /company/:id or /company/:id/ -> /company/:id/company-details
          navigate(`${currentPath}/${nextPathLastSegment}`, {
            replace: true,
          });
        }
      }
    }
  };

  return (
    <div className={`${className} ${isMobile ? "mobile" : ""}`}>
      <div
        onMouseEnter={() => setIsSideBarExpanded(true)}
        onMouseLeave={() => setIsSideBarExpanded(false)}
      >
        <BoSidebar isExpanded={isSideBarExpanded} />
      </div>

      <div
        className={`${isSideBarExpanded ? "bo-container-w-sidebar-expanded" : ""} bo-container`}
      >
        <BoHeader
          setNotifDrawerOpen={setNotifDrawerOpen}
          isSideBarExpanded={isSideBarExpanded}
        />
        <section className="bo-content" ref={boContentRef}>
          <Outlet />
          <BackTopButton layoutContentRef={boContentRef} />
        </section>
      </div>

      <BoNotification
        drawerOpen={notifDrawerOpen}
        setDrawerOpen={setNotifDrawerOpen}
      />
    </div>
  );
};

const PrivateLayout = styled(RawPrivateLayout)`
  height: 100%;
  width: 100%;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;

  .bo-container {
    width: calc(100% - 4.5rem);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 4.5rem;
    transition:
      width 0.6s ease-in-out,
      left 0.6s ease-in-out;
  }

  .bo-container-w-sidebar-expanded {
    width: calc(100% - 18.19rem) !important;
    left: 18.19rem !important;
  }

  .bo-content {
    padding: var(--bo-spacing-inline);
    position: relative;
    top: var(--bo-header-height);
    max-height: calc(100vh - var(--bo-header-height));
    height: 100%;
    overflow-y: scroll;
  }
`;

export default PrivateLayout;
