import { useEffect, useState } from "react";
import styled from "styled-components";
import ErrorPage from "@/pages/error-page";

const ErrorBoundary = (props) => {
  const { children } = props || {};

  const [hasError, setHasError] = useState(false);
  const [errorType, setErrorType] = useState(null);

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      console.error("Error caught by ErrorBoundary:", error, errorInfo);

      const type = error.message.includes("404") ? "404" : "500";
      setErrorType(type);
      setHasError(true);
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  if (hasError) {
    // Fallback UI for error
    return <ErrorPage errorType={errorType} />;
  }

  return children;
};

const StyledComponent = styled(ErrorBoundary)``;

export default StyledComponent;
