import "antd/dist/reset.css"; // CSS files are no longer included in package
import "@/styles/main.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import AntDesignConfig from "@/constants/AntDesignConfig";
import reportWebVitals from "./reportWebVitals";
import Router from "@/router";
import { Provider } from "react-redux";
import store from "@/store";
import { I18nextProvider } from "react-i18next";
import { i18next } from "@/locale";
import ErrorBoundary from "@/pages/error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AntDesignConfig>
          <I18nextProvider i18n={i18next}>
            <div id="app">
              <ErrorBoundary>
                <Router />
              </ErrorBoundary>
            </div>
          </I18nextProvider>
        </AntDesignConfig>
      </Provider>
    </React.StrictMode>
  );
};

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
