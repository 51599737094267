import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hasAnyRole } from "@/utils/helpers";
import { protectedRoute } from "@/utils/routeUtils";
import RouteTypeValidationBasedOnRole from "../components/RouteTypeValidationBasedOnRole";

const moduleTitle = "module_title.work_order";
const allowedRoles = ["isRequester", "isReviewer"];
const allowedRoleWithType = [
  { role: ["isRequester"], type: ["request"] },
  { role: ["isReviewer"], type: ["pending", "history"] },
];

export const RedirectToList = () => {
  const userRoles = useSelector((state) => state.auth?.userRoles);
  const navigate = useNavigate();

  useEffect(() => {
    if (userRoles?.length > 0) {
      navigate(
        hasAnyRole(userRoles, "isRequester")
          ? "/work-order/request"
          : "/work-order/pending",
        { replace: true }
      );
    }
  }, [userRoles, navigate]);

  return null;
};

const workOrderRoutes = {
  path: "work-order",
  title: moduleTitle,
  sidebarProps: {
    key: "1-2",
    iconSrc: "work-order",
    label: moduleTitle,
    link: "/work-order",
    groupingType: "TOOLS",
    order: 2,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: <RedirectToList />,
    },
    {
      path: ":type",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          element: (
            <RouteTypeValidationBasedOnRole
              allowedRoleWithType={allowedRoleWithType}
              fallback={protectedRoute(
                [() => import("@/pages/work-order/WorkOrderList")],
                allowedRoles
              )}
            />
          ),
        },
        {
          path: ":id",
          title: moduleTitle,
          headerProps: {
            backUrl: "/work-order/:type",
          },
          element: (
            <RouteTypeValidationBasedOnRole
              allowedRoleWithType={allowedRoleWithType}
              fallback={protectedRoute(
                [() => import("@/pages/work-order/WorkOrderDetails")],
                allowedRoles
              )}
            />
          ),
        },
      ],
    },
  ],
};

export default workOrderRoutes;
