import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSelectors } from "@/store/slices/auth";
import { getFirstAccessibleRoute } from "../utils/routeUtils";

const RawErrorPage = ({ className, errorType }) => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const userRoles = useSelector((state) => state.auth.userRoles);

  return (
    <div className={className + " error-page"}>
      <h1 className="error-title">{t("error_page.oops")}!</h1>
      <p className="error-message">
        {t(
          `error_page.${errorType === "404" ? "page_not_found" : "try_again_message"}`
        )}
      </p>
      <a
        href={
          isLoggedIn && userRoles.length
            ? `/${getFirstAccessibleRoute(userRoles)}`
            : "/sign-in"
        }
        className="return-home-link"
      >
        {t("error_page.back_to_home")}
      </a>
    </div>
  );
};

const ErrorPage = styled(RawErrorPage)`
  padding-inline: 2rem;
  padding-block: 4rem;

  .error-title {
    font: normal normal 700 var(--font-size-2xl) var(--default-font);
    margin-bottom: 2rem;
  }

  .error-message {
    font: normal normal 500 var(--font-size-xl) var(--default-font);
    width: 22rem;
    margin-bottom: 1.5rem;
  }

  .return-home-link {
    font: normal normal 300 var(--font-size-l) var(--default-font);
    color: var(--primary-color);
  }
`;

export default ErrorPage;
